<template>
  <div>
      <UtilsToast />
      <slot />
  </div>
</template>

<style>
  img, button, input:focus-visible {
      outline: none;
  }
  .page-enter-active,
  .page-leave-active {
      transition: all 0.5s;
  }
  .page-enter-from,
  .page-leave-to {
      opacity: 0;
      filter: blur(1rem);
  }
</style>
